
.goal-card.card {
    border: none;
}
  
.goal-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Six columns of equal width */
    grid-template-rows: repeat(3, 1fr); /* Three rows of equal height */
    /* gap: 10px; Optional: Add spacing between items */
    gap: 20px;
}

.alignment-goal-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Six columns of equal width */
    grid-template-rows: repeat(10, 1fr); /* Three rows of equal height */
    gap: 10px; /* Optional: Add spacing between items */
}


.alignment-goal-icon{
    width: 50px; /* Ensures image fills the container width */
}

.alignment-goal-item {

} 
.alignments-goals-list {
  
}

.sdg-alignment-subtitle {
    text-align: center;
    padding-bottom: 20px; 
}

.sdg-alignment-title {
    padding-top: 120px;
    text-align: center;
}

.goal-item {
    width: 100%; /* Ensures item fills its cell in the grid */
    height: 100%; /* Ensures item fills its cell in the grid */
}

.goal-icon {
    width: 100%; /* Ensures image fills the container width */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Scales image to fill container while maintaining aspect ratio */
}


.sdg-logo {
    width: 30%;
    display: grid;
}

.sdg-home-title.row {
    padding-top: 20px;
    color: #1b3e51;
    font-weight: bold;
}

@media (max-width: 768px) { /* Targets screens smaller than 768px (typical tablet breakpoint) */
    .goal-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust as needed */
    }
    .sdg-logo {
        width: 80%;   
    }
}

@media (max-width: 576px) { /* Targets screens smaller than 576px (typical mobile breakpoint) */
    .sdg-logo {
        width: 100%;
    }
    .goal-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust as needed */
    }
}

.accordion-body p {
    font-size: 14px;
}

.accordion {
    padding-bottom: 30px;
}


.home-header, .sdg-home-title, .sdg-home-subtitle {
    /* justify-content: center;
    text-align: center; */
}

.sdg-home-subtitle {
    padding-bottom: 30px;
}

.goal-item-footer {
    padding-bottom: 20px;
}

.goal-item-status {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}

.goal-item-status .status-icon {
    font-size: 35px !important;
    font-weight: 500;
}
.goal-item-status .status-indicator {
    margin-top: 5px !important;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: -12px;
    margin-left: -12px;
}

.goal-item .col-md-9 {
    /* padding: 0; */
    padding-right: 0px;
}

.goal-item .col-md-3 {
    /* padding: 0; */
    height: 100%;
    padding-right: 12px;
    padding-left: 0px;
    
}





.goal-item-image-container {
    /* margin: 0; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 1px; */
    align-items: center;
}

.goal-grid-button-see-all-targets {
    border-bottom: 1px solid white;
    padding: 20px 10px 20px 10px;
    text-align: left;
}

.goal-grid-button-see-all-data {
    padding: 20px 10px 20px 10px;
    text-align: left;
}
.goal-item-footer {
    padding-top: 10px
}

.goal-item-footer.row {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
}

.goal-item-footer .col {
    margin: 0px;
    padding: 0;
}

.goal-grid-button-see-all-targets,
.goal-grid-button-see-all-data {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space between text and chevron */
    padding: 20px 10px;
    color: white; /* Set text color to white */
    transition: color 0.3s; /* Smooth color transition */
    align-items: center;
}

.chevron {
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s; /* Smooth transition for the chevron */
}

.goal-grid-button-see-all-targets:hover .chevron,
.goal-grid-button-see-all-data:hover .chevron {
    opacity: 1; /* Show chevron on hover */
    /* margin-right: 10px 0.3s; Space between text and chevron */
}

.goal-grid-button-title-container p {
    margin: 0;
    font-size: 13px;
}

.goal-grid-button-title-container h4 {
    font-size: 22px;
    margin: 0;
}


/* This is flip */

.goal-grid {
    display: flex;
    flex-wrap: wrap;
}

.goal-item {
    perspective: 1000px; /* Enables 3D perspective */
    width: 268px;
    height: 207px;
}

.card-container {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s; /* Smooth flip */
    transform-style: preserve-3d; /* Allows children to maintain their 3D position */
}

.card {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Hides the back face when flipped */
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 1px;
    cursor: auto;
}


.back {
    transform: rotateY(180deg); /* Flips the back side */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f0f0f0; */
}

.back-content {
    width: 100%;
    height: 100%;
}

.goal-item:hover .card-container {
    transform: rotateY(180deg); /* Flips the card on hover */
}

.goal-item-footer.row a {
    color: white;
    text-decoration: none;
}




/* This is the grid header */

.sdg-home-country-title.row, .sdg-home-grit-title.row {
    font-size: 28px;
    font-weight: bold;
    color: #039dda;
    color: #15133c;
    font-family: sans-serif;
}

.sdg-home-grit-title.row {
    padding-top: 20px;
}

.sdg-home-country-title.row {
    padding-top: 20px;
}

.sdg-home-country-title p, .sdg-home-grit-title p {
    margin-bottom: 0px;
    font-size: 24px;
}

.sdg-home-country-subtitle.row, .sdg-home-grit-subtitle.row {
    color: #525252;
    font-style: italic;
}

.sdg-home-country-data-grid {
    display: flex; /* Enable flexbox layout */
    justify-content: left; /* Center items horizontally */
    gap: 20px; /* Space between squares */
    padding-bottom: 30px;
    padding-top: 25px;
}




.data-square {
    width: 220px;
    height: 220px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 1px;
}

.data-content {
    height: 100px;
    font-size: 30px;
    color: #525252;
    font-weight: 300;
    align-content: center;
}

.index-number {
    color: #039dda;
    font-size: 45px;
    font-weight: bold;
}

.data-title {
    font-size: 20px;
    padding-bottom: 15px;
    font-family: sans-serif;
    font-weight: bold;
    color: #1b3e51;
}

.circle {
    position: relative;
    width: 100px;
    height: 100px;
}

.circle-inner {
    width: 100px;
    height: 100px;
    border: 8px solid lightgray; /* Gray background border */
    border-radius: 50%;
}

.circle-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 8px solid #039dda; /* Progress border color */
    clip: rect(0, 50px, 100px, 0); /* Clip half to rotate */
}

.percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #039dda;
}
  

.col.download-pdf {
    padding-bottom: 30px;
}

.grid {
    column-count: 2; /* Number of columns */
    column-gap: 15px; /* Space between columns */
    padding: 10px 0px 25px 0px;
}

.grid-item {
    display: inline-block; /* Allows items to wrap correctly in the column layout */
    width: 100%; /* Ensures items fill the column width */
    /* margin-bottom: 15px; */
    /* border-bottom: 1px solid #ccc; */
    padding-bottom: 3px;
    padding-top: 3px;
    color: #525252;
    background-color: white;
    box-sizing: border-box;
    padding-left: 6px;
}

.grid-item p {
    font-size: 18px;
    padding-left: 15px;
    display: inline;
    
}

.grid-item:hover {
    background-color: #eeeeee;
    cursor: pointer;
    border-radius: 5px;
}

.available-indicators .section-title {
    padding-top: 20px;
}
.section-title h5 {
    font-weight: bold !important;
    font-family: sans-serif !important;
    color: #039dda;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #100e2b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* index.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.flex-grow-1{
  /* padding-top: 60px !important;   */
  background: white;
}

.card {
  cursor: pointer;
}

.card-header {
  cursor: pointer;
}

html {
  overflow-y: scroll;
}

p {
  font-size: 17px;
}

hr {
  color: #9e9a9a;
}

.list-group {
  padding-left: calc(var(--bs-gutter-x)* .5);
}
.targets-list {
  padding-top: 20px;
  padding-bottom: 30px;
}

.accordion-item-container {
  padding-bottom: 20px;
}

.accordion-button:focus {
  box-shadow: none;
}

.list-group-item.active {
  color: #131313;
  /* 100e2b */
  /* background-color: #cfe2ff; */
  /* background-color: #edf4ff; */
  /* border-color: #cfe2ff; */
  background-color: transparent;
  border: transparent;
  margin: 0px;
}
.list-group-item-action:active {
  background-color: transparent;
}

.help-text {
 color: #131313;
}

.accordion-header p {
  margin: 0;
}

.container-fluid {
    height: 100vh;
    /* padding-top: 20px; */
}


@media (min-width: 1400px) {
  .container { 
    /* max-width: 100%;  */
    max-width: 1445px;
  }
}

@media (min-width: 1400px) {
  /* .container {
      padding: 30px;
  }
  .container-fluid {
    height: 100vh;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  } */
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

.targets-column-image { 
  /* height: 100%; */
  height: 91%;
  overflow-y: auto;
}

.targets-column { 
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 1px 30px 10px 30px;
  background: white;
  padding-left: calc((100% - 1445px) / 2);
}

.indicators-column {
  background: #f9f8f8;
  height: 100%;
  overflow-y: auto;
  padding: 30px 0px 20px 30px;
  padding-right: calc((100% - 1445px) / 2);
}
.indicators-column h5{
  color: #323232;
  font-weight: 500;
}

.indicators-column .section-title h6, h5 {
  color: #100e2b;
  /* color: #323232; */
  font-weight: 400;
}

@media (max-width: 576px) { /* Targets screens smaller than 576px (typical mobile breakpoint) */
  .targets-column {
    overflow-y: unset;
  }
}

@media (min-width: 1400px) {
  .goal-details-container {
    height: 100vh;
    max-width: 1445px;
    max-width: 100%;
  }
}

.list-group-item {
  /* font-size: 15px; */
  border: transparent;
  padding: 10px 5px;
}

.sdg-details-left-container {
  display: flex;
  margin-top: 35px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: center;
}

.sdg-details-left-container p {
  margin-bottom: 0px;
  /* margin-top: 10px; */
  /* font-size: 14px; */
}

.list-group-item:first-child {

  padding-top: 0px;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  border-radius: 5px;
  background-color: #eeeeee;
  cursor: pointer;
}

p.small-text {
  font-size: 14px;
  font-style: italic;
  color: #737777;
}

.goal-details-title {
  margin-bottom: 0px;
}


/* Custom styles for the bottom sheet modal */
.bottom-sheet-modal .modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  height: 80%;
  animation: slideInUp 0.3s ease-out;
}

.bottom-sheet-modal .modal-content {
  height: 100%;
  border-radius: 15px 15px 0 0;
}

.bottom-sheet-modal .modal-header {
  border-bottom: none;
}

.bottom-sheet-modal .modal-body {
  overflow-y: auto;
}

.accordion-body h6 {
  font-weight: 600;
  color: #353941;
}

.accordion-body p {
  font-size: 15px;
}

.MuiGrid-item {
  padding-top: 0px !important;
  padding-bottom: 15px;
}

.graph-buttons button {
  margin-right: 10px !important;
}

.graph-buttons {
  color: #00b3af !important;
}

.graph-buttons button {
  margin-right: 10px !important;
  color: #333333;
  border-color: #00b3af;
}

.graph-buttons button:hover {
  margin-right: 10px !important;
  border-color: rgba(0, 179, 175, 0.04)
}

.navbar {
  /* height: 60px; */
  height: 9%;
  color: white !important;
  /* background: #1b3e51 !important; */
  background: #100e2b !important;
  position: fixed;
  width: 100vw;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

a.nav-link {
  color: white;
}

.nav-link:focus, .nav-link:hover {
  color: white;
  text-decoration: underline;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.navbar .container {
  /* padding-left: 0px;
  padding-right: 0px;
  margin-left: 30px;
  margin-right: 30px; */
  padding-bottom: 0px;
  padding-top: 0px;
  /* max-width: 100%; */
}

.navbar-brand {
  /* padding-left: 50px; */
}

.home-header {
  padding-top: 130px;
}

.goal-grid { 
  padding-bottom: 50px;
}


/* MARK: CUSTOM CHECKBOX */
/* Check box custom */
.seriesHideShowContainer {
  padding-left: 30px;
  padding-top: 10px;
}

.displaySeriesContainer {
  padding-right: 15px;
  float: left;
  display: inline-flex;
}

.displaySeriesContainer p {
  cursor: pointer;
}

label.container {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50%;
  width: 20px;
  position: relative;
  float: left;
}

/* Hide the browser's default checkbox */
label.container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  float: left;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ebebeb;
  border-radius: 50%
}

/* On mouse-over, add a grey background color */
label.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
label.container input:checked ~ .checkmark {
  background-color: #aeb3b6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
label.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
label.container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}
  

.targets-list .status-indicator {
  padding-right: 10px;
}


/* STATUS CIRCLE */
.status-circle {
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}


.container-full {
  top: 9% !important;
  position: fixed !important;
  width: 100%;
  background: #f9f8f8;
  /* background: #100e2b; */
}

.section-title h6, h5 {
  color: #a9a9a9;
  padding-bottom: 8px;
  margin: 0;
}

/* MARK: Accordion active */
.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #365d73;
  background-color: #100e2b;
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  filter: invert(0.7);
}


a.link-offset-2.link-underline.link-underline-opacity-25 {
  text-decoration: none;
}

/* styles.css or your relevant stylesheet */
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}


/* MARK: Plain text field */
.chart-plain-text-value {
  font-weight: 500;
  color: #22475a;
}
.chart-plain-text-label {
  float: left;
  padding-right: 6px;
}
.row-plaint-text {
  display: inline-block;
  width: 100%;
  float: left;
  padding-top: 20px;
  margin: 0;
}


.goal-info-alignment-with-sdg {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.line {
  width: 100%;
  height: 1px;
  background: lightgrey;
}
.legend-grid-container {
    display: grid;
    grid-template-columns: repeat(5, max-content); /* 4 columns, each adjusts to the largest content */
    column-gap: 15px; /* Space between columns */
    row-gap: 0px; /* Space between rows */
    justify-content: flex-start; /* Align grid items to the left */
  }
  
  .legend-grid-column {
    display: flex;
    flex-direction: column;
    white-space: nowrap; /* Prevent text wrapping */
    color: #525252;
  }
  
  .status-item,
  .alignment-item {
    padding: 5px;
    white-space: nowrap; /* Prevent text wrapping */
  }
  